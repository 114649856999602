import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "fr",
    detection: {
      order: ["queryString", "cookie"],
      cache: ["cookie"],
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false, //   <---- this will do the magic
    },
  });

const detectedLang = (navigator.language || navigator.userLanguage)?.split(
  "-"
)[0];
let lang;
if (localStorage.getItem("lang")) {
  lang = localStorage.getItem("lang");
} else {
  lang = ["en", "fr", "es"].includes(detectedLang) ? detectedLang : "en";
}
i18n.changeLanguage(lang);

export default i18n;
